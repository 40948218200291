import { Box } from "@mantine/core";

type Principle = {
  index: number;
  emoji: string;
  title: string;
  principles: React.ReactNode[];
};

const PrincipleContent = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => (
  <div className="flex flex-col">
    <h2 className="text-lg text-bold text-text-primary mb-1">{title}</h2>
    <p className="text-text-secondary">{description}</p>
  </div>
);

const Testimonial = ({ quote, author }: { quote: string; author: string }) => (
  <div className="flex flex-col">
    <p className="text-text-primary mb-1">{`"${quote}"`}</p>
    <p className="text-text-secondary text-md">{`-${author}`}</p>
  </div>
);
const principles: Principle[] = [
  {
    index: 0,
    emoji: "📚",
    title: "Grounded in research",
    principles: [
      <PrincipleContent
        key={1}
        title="Spacing: learning across time"
        description="To build durable knowledge, learners have to repeatedly study and use the information they are trying to learn. Repeated practice opportunities that are spaced apart in time are more effective than the same number of practice opportunities that occur closer together in time."
      />,
      <PrincipleContent
        key={2}
        title="Retrieval: retrieving information from memory"
        description="Bringing knowledge back from memory is a powerful way to solidify learning. When compared with study strategies that do not involve recalling information, retrieval practical consistently results in better learning outcomes."
      />,
      <PrincipleContent
        key={3}
        title="Successive Relearning"
        description=" Spacing and retrieval can be combined to enhance learning more effectively than either strategy alone. Retrieving information repeatedly over spaced time intervals produces durable and long-lasting benfits to learning."
      />,
    ],
  },
  {
    index: 1,
    emoji: "💪",
    title: "Empowering teachers",
    principles: [
      <PrincipleContent
        key={4}
        title="Free up teachers' time"
        description="We're building to help with (not add to) the straining demands on a teacher's time. The more we can help with setting you up for success at the beginning, the morew e hope you are freed up to focus on what matters most throuhout the day --teaching, supporting, and caring for your students."
      />,
      <PrincipleContent
        key={5}
        title="Supercharge teachers' superpowers"
        description="Teachers have so much expertise. We're here to amplify this expertise by further developing and extending its depth and reach."
      />,
    ],
  },
  {
    index: 2,
    emoji: "🌱",
    title: "Delivering learning impact",
    principles: [
      <Testimonial
        key={6}
        quote="The free program Podsie dramatically increased both engagement and results of my high school students using the evidence-backed techniques of retrieval practice and spaced repetition. The average grade improved from 21% below to 38% above national standards."
        author="Science & Physics Teacher"
      />,
      <Testimonial
        key={7}
        quote="Podsie has helped reduce my workload as a teacher! The making of many paper copies has gone, it is very quick to check pupils and their progress, in much more detail as well."
        author="Michael R, Math Teacher"
      />,
      <Testimonial
        key={8}
        quote="I love the fact that the 'spaced practice' aspect of Podsie is supported by cognitive research. Podsie has been an amazing way for my students to study all of the details they need to know, while also challenging them to expand their ways of thinking."
        author="Emma B, Science Teacher"
      />,
    ],
  },
];

export default function PodsiePrinciples() {
  return (
    <Box className="max-w-screen-md my-0 mx-auto flex flex-col">
      {principles.map((principle) => {
        return (
          <div
            key={principle.index}
            className="flex justify-center gap-4 mb-12"
          >
            <h2 className="text-6xl">{principle.emoji}</h2>
            <Box className="flex flex-col">
              <h3 className="text-2xl text-bold text-text-primary mb-4">
                {principle.title}
              </h3>
              {principle.principles.map((content, i) => (
                <div key={`${principle.index}-${i}`} className="mb-5">
                  {content}
                </div>
              ))}
            </Box>
          </div>
        );
      })}
    </Box>
  );
}
