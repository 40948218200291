import { Box, Button, Grid, Group, Image, Text } from "@mantine/core";
import type { MetaFunction } from "@remix-run/node";
import { Link, useOutletContext } from "@remix-run/react";
import AuthButton from "~/components/Auth/AuthButton";
import PodsiePrinciples from "~/components/LandingPage/PodsiePrinciples";
import ProductFeatures from "~/components/LandingPage/ProductFeatures";
import AppLayout from "~/layouts/_app";
import { OutletContext } from "~/root";

export const meta: MetaFunction = () => {
  return [
    { title: "Podsie" },
    {
      name: "description",
      content: "Podsie helps your students remember what they learn!",
    },
  ];
};
export default function Home() {
  const { user } = useOutletContext<OutletContext>();
  return (
    <AppLayout navRoutes={[]}>
      <Box className="bg-secondary-200">
        <Grid
          className="p-8 flex justify-center max-w-screen-md my-0 mx-auto text-text-primary"
          gutter={{ base: 10, xs: "md", md: "xl", xl: 60 }}
        >
          <Grid.Col
            span={{
              sm: 6,
              xs: 0,
            }}
            className="mantine-visible-from-md"
          >
            <Image
              src="/assets/podsie-hero.svg"
              alt="Student studying on laptop"
            />
          </Grid.Col>
          <Grid.Col
            span={{
              md: 6,
              xs: 12,
            }}
          >
            <h1 className="text-6xl font-bold text-text-primary leading-tight md:text-4xl md:leading-normal m-0 p-0">
              Podsie helps your students remember what they learn!
            </h1>

            <Text className="mt-8 text-2xl text-text-secondary md:text-xl">
              Podsie transforms your classroom lessons and turns these into
              personalized review decks for your students.
            </Text>

            <Group className="mt-16 md:mt-8">
              {user ? (
                <>
                  <Link to="/teacher" className="flex-1">
                    <Button
                      size="xl"
                      className="h-14 px-10 md:h-14 md:px-4 w-full"
                      color="secondary"
                    >
                      Teacher site
                    </Button>
                  </Link>

                  <Link to="/student" className="flex-1" reloadDocument>
                    <Button
                      size="xl"
                      color="primary"
                      variant="filled"
                      className="h-14 px-10 md:h-14 md:px-4 w-full"
                    >
                      Student site
                    </Button>
                  </Link>
                </>
              ) : (
                <AuthButton />
              )}
            </Group>
          </Grid.Col>
        </Grid>
      </Box>
      <Box className="bg-secondary-50 p-16">
        <Text className="text-center text-4xl font-bold text-text-primary mb-12">
          Podsie does this by...
        </Text>
        <ProductFeatures />
      </Box>
      <Box className="bg-secondary-200 p-16">
        <Text className="text-center text-4xl font-bold text-text-primary mb-12">
          Podsie&apos;s Principles
        </Text>
        <PodsiePrinciples />
      </Box>
    </AppLayout>
  );
}
